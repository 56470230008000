import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/Home.vue'
// import SoftDownload from '@/views/home/SoftDownload.vue'
// import StuVideo from '@/views/home/StuVideo.vue'
// import StuDoc from '@/views/home/StuDoc.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: HomeView,
    name: 'home',
    children: [
      // {
      //   path: '/home',
      //   redirect: '/home/softDownload'
      // },
      // {
      //   path: 'softDownload',
      //   component: SoftDownload,
      //   name: 'softDownload'
      // },
      // {
      //   path: 'stuVideo',
      //   component: StuVideo,
      //   name: 'stuVideo'
      // },
      // {
      //   path: 'stuDoc',
      //   component: StuDoc,
      //   name: 'stuDOc'
      // }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
