<template>
  <div v-if="!isPC">
    <header :style="{height: scHeight * 0.1 + 'px'}">
      <div class="header-item" :style="{width: scWidth*0.7+'px'}">
        <div id="title">
          数信学园
        </div>
        <div id="goConsole">
          <!-- <el-button type="primary">控制台入口</el-button> -->
        </div>
      </div>
    </header>
    <section :style="{height: scHeight * 0.055 + 'px'}">
      <div class="header-item" :style="{width: scWidth*0.7+'px', height: scHeight * 0.055 + 'px'}">
        <div id="section-menu" :style="{height: scHeight * 0.055 + 'px'}">
          <el-menu :default-active="defaultActive" :router="false" mode="horizontal" background-color="#409EFF" text-color="#fff" active-text-color="#ffd04b" :unique-opened="true">
            <el-menu-item v-for="(item, index) in menuListInfo" :key="index" :index="'/' + index" @click="setitem(index, item.backRequestNameUrl, item.backRequestInfoUrl, item.sqlTableName)">{{ item.menuTitle }}</el-menu-item>
            <!-- <el-menu-item index="/home/stuVideo" @click="setitem('/home/stuVideo')">学习视频</el-menu-item>
            <el-menu-item index="/home/stuDoc" @click="setitem('/home/stuDoc')">学习文档</el-menu-item> -->
          </el-menu>
        </div>
      </div>
    </section>
    <footer>
      <div class="header-item" :style="{width: scWidth*0.7+'px'}">
        <div>
          <class-info :back-request-info-url="backRequestInfoUrl" :back-request-name-url="backRequestNameUrl" :sql-table-name="sqlTableName"/>
        </div>
      </div>
    </footer>
  </div>
  <div v-else style="display: flex;align-items: center;justify-content: center;">
    手机端暂不支持，请使用电脑访问
  </div>
</template>

<script>
import requestUrl from '@/api/requestUrl'
import ClassInfo from '@/components/ClassInfo.vue'
import axios from 'axios'
export default {
  name: 'HomePage',
  components: { ClassInfo },
  data () {
    return {
      scWidth: window.screen.width,
      scHeight: window.screen.height,
      backRequestInfoUrl: '',
      backRequestNameUrl: '',
      sqlTableName: '',
      menuListInfo: [],
      menuLoad: false,
      deActive: ''
    }
  },
  mounted () {
    document.title = '数信学园'
    this.backRequestInfoUrl = (sessionStorage.getItem('backRequestInfoUrl') === null ? '' : sessionStorage.getItem('backRequestInfoUrl'))
    this.backRequestNameUrl = (sessionStorage.getItem('backRequestNameUrl') === null ? '' : sessionStorage.getItem('backRequestNameUrl'))
    this.sqlTableName = (sessionStorage.getItem('sqlTableName') === null ? '' : sessionStorage.getItem('sqlTableName'))
    this.$el.style.setProperty('--screen-height', this.scHeight * 0.055 + 'px')
    axios.get(requestUrl + '/api/getData/menuList')
      .then(res => {
        this.menuListInfo = res.data.data
        this.menuLoad = true
        if (this.backRequestInfoUrl === '') {
          this.backRequestInfoUrl = res.data.data[0].backRequestInfoUrl
          this.backRequestNameUrl = res.data.data[0].backRequestNameUrl
          this.sqlTableName = res.data.data[0].sqlTableName
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    goto (url) {
      window.open(url, '_blank')
    },
    setitem (num, name, info, tableName) {
      sessionStorage.setItem('index', '/' + num)
      this.backRequestNameUrl = name
      this.backRequestInfoUrl = info
      this.sqlTableName = tableName
      sessionStorage.setItem('backRequestNameUrl', name)
      sessionStorage.setItem('backRequestInfoUrl', info)
      sessionStorage.setItem('sqlTableName', tableName)
      // console.log('setUrl:' + this.backRequestNameUrl)
    }
  },
  created () {
    this.scWidth = window.screen.width
  },
  computed: {
    defaultActive () {
      // console.log(sessionStorage.getItem('index'))
      if (sessionStorage.getItem('index') === null) {
        sessionStorage.setItem('index', '/0')
      }
      return sessionStorage.getItem('index')
    },
    isPC () {
      // 获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      const info = navigator.userAgent
      // 通过正则表达式的test方法判断是否包含“Mobile”字符串
      const isPhone = /mobile/i.test(info)
      // 如果包含“Mobile”（是手机设备）则返回true
      return isPhone
    }
  },
  watch: {
    menuLoad (newVal, oldVal) {
      if (newVal === true) {
        this.deActive = this.menuListInfo[0].menuTitle
      }
    }
  }
}
</script>

<style scoped lang="css">
  header{
    width: 100%;
    /* outline-style: auto; */
  }
  .header-item{
    height: 100%;
    margin: 0 auto;
  }
  #title{
    height: 100%;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 190%;
    float: left;
  }
  #goConsole{
    width: 15%;
    height: 100%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  section{
    width: 100%;
    /* outline-style: auto; */
    background-color: #409EFF;
  }
  .el-menu-item{
    border-bottom: none !important;
    font-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-submenu__title{
    font-size: 100% !important;
  }
</style>
<style lang="scss">
$scHeight: var(--screen-height);

.el-menu{
  &-item,
  &-submenu,
  &-submenu__title {
    height: $scHeight !important;
    border: none !important;
    text-align: center;
  }
  border: none !important;
  height: $scHeight !important;
}
</style>
