<template>
  <div id="app" :style="{minHeight: scHeight + 'px', minWidth: scWidth + 'px'}">
    <router-view :style="{minHeight: scHeight + 'px', minWidth: scWidth + 'px'}"/>
  </div>
</template>

<style>
*{
  margin: 0%;
  padding: 0%;
}
.el-submenu__title{
  height: inherit !important;
  font-size: 100% !important;
}
#app{
  width: 100%;
}
</style>
<script>
export default {
  data () {
    return {
      scHeight: window.screen.height * 0.8,
      scWidth: window.screen.width * 0.8
    }
  },
  mounted () {
  }
}
</script>
