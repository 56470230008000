<template>
    <div :style="{padding: scHeight * 0.02 + 'px 0'}">
      <el-menu active-text-color="#E6A23C" mode="vertical" background-color="#ffffff" text-color="" :style="{height: scHeight * 0.05 * dataInfo.length + 'px !important'}">
        <el-menu-item v-for="(item, index) in dataInfo" :index="item.className" :key="index" @click="nowCheckChange(item.className)" style="font-size: 110% !important;display: flex !important;align-items: center !important;justify-content: center;" :style="{color: item.className === nowActive ? '#409EFF !important' : '#000000 !important', height: scHeight * 0.05 + 'px !important'}">{{ item.className }}</el-menu-item>
      </el-menu>
      <div id="view">
        <ul :style="{minHeight: scHeight * 0.05 * dataInfo.length + 'px'}">
          <li v-for="(item, index) in listInfo" :key="index" @click="goto(item.url)" :style="{color: item.title[3] === '>' ? 'blue' : 'black'}">{{ '（' + (index + 1) + '）' + item.title }}</li>
        </ul>
      </div>
    </div>
  </template>

<script>
import requestUrl from '@/api/requestUrl'
import axios from 'axios'

export default {
  props: {
    backRequestNameUrl: String,
    backRequestInfoUrl: String,
    sqlTableName: String
  },
  data () {
    return {
      menuListInfo: [
        {
          name: 'Python',
          index: '/home/softDownload/python'
        },
        {
          name: 'Java JDK',
          index: '/home/softDownload/java'
        },
        {
          name: 'HBuilder',
          index: '/home/softDownload/hbuilder'
        }
      ],
      dataInfo: [
      ],
      listInfo: [],
      allData: [],
      nowActive: '',
      scHeight: window.screen.height
    }
  },
  methods: {
    redirect (url) {
      window.open('')
    },
    goto (url) {
      window.open(url, '_blank')
    },
    getData () {
      let name = ''
      const self = this
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // console.log('get data run,' + requestUrl + this.backRequestNameUrl)
      axios.get(requestUrl + this.backRequestNameUrl, {
        params: {
          tableName: this.sqlTableName
        }
      })
        .then(res => {
          loading.close()
          this.dataInfo = res.data.data
          name = res.data.data[0].className
          this.nowActive = name
          axios.get(requestUrl + this.backRequestInfoUrl, {
            params: {
              tableName: this.sqlTableName
            }
          })
            .then(res => {
              self.allData = res.data.data
              self.listInfo = self.allData.filter(item => item.className === name)
            })
        })
        .catch(error => {
          console.log(error)
        })
    },
    nowCheckChange (name) {
      this.listInfo = this.allData.filter(item => item.className === name)
      this.nowActive = name
    }
  },
  mounted () {

  },
  computed: {
  },
  watch: {
    backRequestInfoUrl (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getData()
      }
    },
    backRequestNameUrl (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getData()
      }
    },
    sqlTableName (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getData()
      }
    }
  }
}
</script>

  <style scoped>
  #sd-ul{
    width: 100%;
  }
  #sd-ul li{
    list-style-type: none;
    width: 20%;
    height: 6vh;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
  }
  #sd-ul li:hover{
    background-color: #bcbcbc;
    cursor: pointer;
  }
  .el-menu{
    width: 20%;
    float: left;
    border: #c6e1ff solid 2px !important;
    border-radius: 15px !important;
  }
  #view{
    float: left;
    width: 75%;
    margin: 0 2%;
    padding: 2% 0;
    border: #c6e1ff 2px solid;
    border-radius: 15px;
  }
  #view>ul{
    width: 95%;
    padding: 0 2.5%;
    height: 100%;
  }
  #view li:first-child{
    /* border-top-left-radius: 15px;
    border-top-right-radius: 15px; */
  }
  #view li:last-child{
    /* border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; */
    border: none !important;
  }
  #view>ul li{
    height: 5vh;
    padding: 0 5%;
    width: 90%;
    /* outline-style: auto; */
    list-style-type: none;
    display: flex;
    align-items: center;
    font-size: 110%;
    border-bottom: #c6e1ff solid 1px;
    cursor: pointer;
  }
  #view li:hover{
    background-color: #ecf5ff;
  }
  .el-menu-item{
    /* border-bottom: solid #bcbcbc 1px !important;
    border-right: solid #bcbcbc 1px !important;
    border-left: solid #bcbcbc 1px !important; */
  }
  .el-menu-item:first-child{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .el-menu-item:last-child{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .el-menu-item:hover{
    background-color: #ecf5ff !important;
  }
  </style>
